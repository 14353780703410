<template>
    <metainfo></metainfo>
    <section class="overflow-x-hidden">
        <header class="w-screen h-screen/2">
            <div class="film w-full h-full flex">
                <div class="mx-auto my-auto">
                    <h1 class="text-6xl md:text-8xl mx-auto my-auto uppercase text-gray-50 font-semibold">SHOWS</h1>
                </div>
            </div>
        </header>
        <div class="md:grid md:grid-cols-2 md:gap-4 px-2 lg:pl-4 lg:pr-24 3xl:pl-72 3xl:pr-96 py-12 pb-24 space-y-8 md:space-y-0 relative bottom-32">
            <div>
                <div class="w-full bg-white cursor-pointer hover:shadow-xl shadow-lg rounded-2xl lg:rounded-4xl">
                    <div class="rounded-lg">
                        <img 
                            src="@/assets/img/tta.jpg" 
                            alt="Trash Talk Analysys" 
                            class="rounded-t-xl lg:rounded-t-3xl">
                        <div class="flex px-4 justify-between py-4">
                            <h2 class="my-auto font-bold text-lg">Trash Talk Analysis</h2>
                            <a 
                                target="_blank"
                                href="https://www.youtube.com/playlist?list=PLFOMBLH0TmmGFKg04PgZUNl7BZLxIxI12">
                                <img 
                                    src="@/assets/img/youtube.png" 
                                    alt="Watch A Battle With Fear on Youtube"
                                    class="w-28 shadow rounded-lg">
                            </a>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div>
                <div class="w-full bg-white cursor-pointer hover:shadow-xl shadow-lg rounded-2xl lg:rounded-4xl">
                    <div class="rounded-2xl">
                        <img 
                            src="@/assets/img/tta-ad.jpg" 
                            alt="Trash Talk Analysis - After Dark" 
                            class="rounded-t-xl lg:rounded-t-3xl w-full">
                        <div class="flex px-4 justify-between py-4">
                            <h2 class="my-auto font-bold text-lg"> Trash Talk Analysis After Dark</h2>
                            <a 
                                target="_blank"
                                href="https://www.youtube.com/playlist?list=PLFOMBLH0TmmEywwriE5CXJ98cFh7uIarf">
                                <img 
                                    src="@/assets/img/youtube.png" 
                                    alt="Watch A Battle With Fear on Youtube"
                                    class="w-28 shadow rounded-lg">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Shows',
    metaInfo() {
        return {
            title: 'Shows | PlatinumTags Media',
        }
    },
    mounted(){
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    },

    data(){
        return {
        }
    }
}
</script>

<style scoped>
    header{
        background: url('../assets/img/tv-2.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .soc{
        background: url('../assets/img/soc.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    header>.film{
        background: rgb(0, 0, 0, 0.8);
    }
</style>