<template>
    <metainfo></metainfo>
    <section>
        <header class="w-screen h-screen/2">
            <div class="film w-full h-full flex">
                <div class="mx-auto my-auto">
                    <h1 class="text-6xl md:text-8xl mx-auto my-auto uppercase text-gray-50 font-semibold">Movies</h1>
                    <p class="font-medium text-lg md:text-2xl text-gray-300 uppercase">& Short Films</p>
                </div>
            </div>
        </header>
        <div class="md:grid md:grid-cols-2 md:gap-4 px-2 lg:pl-4 lg:pr-24 3xl:pl-72 3xl:pr-96 py-12 pb-24 space-y-8 md:space-y-0">
            <div>
                <div class="w-full bg-white cursor-pointer hover:shadow-xl shadow-lg rounded-lg">
                    <div class="rounded-lg">
                        <img 
                            src="@/assets/img/soc.jpg" 
                            alt="Son of A Crime" 
                            class="rounded-t-lg">
                        <div class="flex px-4 justify-between py-4">
                            <h2 class="my-auto font-medium text-lg">Son of A Crime</h2>
                            <a 
                                target="_blank"
                                href="https://www.amazon.com/gp/video/detail/B088PB8WW8/ref=share_ios_movie">
                                <img 
                                    src="@/assets/img/prime-video.png" 
                                    alt="Watch Son of A Crime on Prime Video"
                                    class="w-32 shadow rounded-lg">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="w-full bg-white cursor-pointer hover:shadow-xl shadow-lg rounded-lg">
                    <div class="rounded-lg">
                        <img 
                            src="@/assets/img/fear-cover.jpg" 
                            alt="A Battle With Fear" 
                            class="rounded-t-lg">
                        <div class="flex px-4 justify-between py-4">
                            <h2 class="my-auto font-medium text-lg">A Battle With Fear</h2>
                            <a 
                                target="_blank"
                                href="https://youtu.be/q3cMHeQG4fU">
                                <img 
                                    src="@/assets/img/youtube.png" 
                                    alt="Watch A Battle With Fear on Youtube"
                                    class="w-28 shadow rounded-lg">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Movies',
    metaInfo() {
        return {
            title: 'Movies | PlatinumTags Media',
        }
    },
    mounted(){
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    },

    data(){
        return {
        }
    }
}
</script>

<style scoped>
    header{
        background: url('../assets/img/tv-2.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .soc{
        background: url('../assets/img/soc.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    header>.film{
        background: rgb(0, 0, 0, 0.8);
    }
</style>